<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="产品名称">
					<c-input name="product_name"></c-input>
				</m-search-item>
<!--				<m-search-item label="产品编码">
					<c-input name="product_code"></c-input>
				</m-search-item>-->
				<m-search-item label="工序名称">
					<c-input name="processes_name"></c-input>
				</m-search-item>
				<m-search-item label="员工姓名">
					<c-input name="yg_name"></c-input>
				</m-search-item>
				<m-search-item label="报工时间">
					<c-datetime type="date" range start-name="start_date" end-name="end_date" ></c-datetime>
				</m-search-item>
			</m-search>

			<m-operate>
				<c-button v-if="auth('m_worke_report')" color="sys" @click="addWorkerRecord">开始报工</c-button>
				<c-button v-if="auth('b_worker_record_export')" color="sys" @click="exportData">导出</c-button>
			</m-operate>

			<c-table ref="table" height="grow">

				<c-table-column
					label="报工日期"
					width="80"
					name="work_date"
				></c-table-column>
				
				<c-table-column
					label="员工"
					width="80"
					name="yg_name"
				></c-table-column>

				<c-table-column
					label="产品"
					width="300"
				>
					<template #default="{data}">
						<span>{{data.product_name}}</span>
						<span v-if="data.product_code">【{{data.product_code}}】</span>
					</template>
				</c-table-column>
				
				<c-table-column
					label="工序"
					width="80"
					name="processes_name"
				></c-table-column>
				
				<c-table-column
					label="报工数"
					width="80"
					name="product_count"
				></c-table-column>
				
				<c-table-column
					label="单价"
					width="70"
					name="product_price"
				>
					<template v-slot="{data}">
						￥{{data.product_price}}
					</template>
				</c-table-column>
				
				<c-table-column
					label="小计"
					width="80"
					name="product_money"
				>
					<template v-slot="{data}">
						￥{{data.product_money}}
					</template>
				</c-table-column>
				
				<c-table-column
					label="操作人"
					width="80"
					name="create_user_realname"
				></c-table-column>
				
				<c-table-column
					label="操作时间"
					width="135"
					name="create_date"
				></c-table-column>
				
				<c-table-column
					label="最后变更人"
					width="80"
					name="edit_user_realname"
				></c-table-column>
				
				<c-table-column
					label="最后变更时间"
					width="135"
					name="edit_date"
				></c-table-column>
				
				<c-table-column
					label="变更原因"
					width="140"
					name="remark"
				></c-table-column>

				<c-table-column
					type="button"
					label="操作"
					width="115"
				>
					<template v-slot="{data}">
						<c-table-button v-if="auth('b_worker_record_edit')" @click="editWorkerRecord(data)">变更</c-table-button>
						<c-table-button v-if="auth('b_worker_record_edit')" @click="deleteWorkerRecord(data)">删除</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>

		<!--变更报工弹框-->
		<c-dialog ref="workerRecordEdit" title="变更报工" width="500" @resolve="submitWorkerRecord">
			<c-form ref="workerRecordForm" unit-width="100%">
				<c-form-item label="员工" >
					<c-input name="yg_name" maxlength="50" disabled></c-input>
				</c-form-item>
				<c-form-item label="产品名称" >
					<c-input name="product_name" maxlength="200" disabled></c-input>
				</c-form-item>
<!--				<c-form-item label="产品编码" >
					<c-input name="product_code" maxlength="50" disabled></c-input>
				</c-form-item>-->
				<c-form-item label="工序" >
					<c-input name="processes_name" maxlength="50" disabled></c-input>
				</c-form-item>
				<c-form-item label="报工单价">
					<c-input type="number" decimal="5" name="product_price" range="0.00001,99999"></c-input>
				</c-form-item>
				<c-form-item label="报工数量">
					<c-input type="number" name="product_count" range="1,9999999"></c-input>
				</c-form-item>
				<c-form-item label="变更原因">
					<c-input name="remark" maxlength="100"></c-input>
				</c-form-item>
				<c-input type="hidden" name="id"></c-input>
			</c-form>
		</c-dialog>

  </page>
</template>

<script>
import {cryptoJs} from '@deanwu/vue-component-library'
import {mapState} from 'vuex'

export default {
	name: 'm_worker_record',
	components: {
    
	},
	data() {
		return {
			formData:null
		}
	},
	computed: {
		...mapState(['userInfo'])
	},
	mounted() {
		this.$refs.search.search();
		this.$bus.$on('workerRecordList.update', () => {
			this.$refs.search.search();
		})
	},
	beforeDestroy() {
		this.$bus.$off('workerRecordList.update');
	},
	methods: {
		//搜索
		searchHandle(data) {
			this.$refs.table.load({
				url: '/worker_record/list',
				data
			});
			this.formData = data;
		},
		//跳转至绩效报工页面
		addWorkerRecord() {
			//TODO
			this.navigateTo('/work_report');
		},
		//变更报工数据
		editWorkerRecord(val) {
			this.$refs.workerRecordForm.clear();
			val.remark="";
			this.$refs.workerRecordForm.set(val);
			this.$refs.workerRecordEdit.open();
		},
		//删除绩效报工
		deleteWorkerRecord(val){
			this.$confirm({
				title: '确认',
				message: '确定要删除【'+val.yg_name+'】产品【'+val.product_name+'】的报工记录吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/worker_record/delete',
						data: {id:val.id},
						loading: true,
						success: data => {
							this.$message({
							  message: '删除成功',
							  type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//提交保存
		submitWorkerRecord(stop) {
			stop();
			this.$refs.workerRecordForm.submit({
				url: '/worker_record/edit',
				rule: {
					product_price: '请填写报工单价',
					product_count: '请输入报工数量',
					remark: '请输入变更原因'
				},
				dataFormatter: data => {
					// data.product_money = parseFloat(data.product_price) * parseFloat(data.product_count);
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '变更成功',
						type: 'success'
					});
					this.$refs.table.update();
					this.$refs.workerRecordEdit.close();
				}
			});
		},
		exportData(){
			this.request({
				url: 'export/getURL',
				data: {
					template_id: 2      //工资数据
				},
				loading: true,
				success: data => {
					var export_url = data;
					if (this.formData && Object.keys(this.formData).length > 0){
						for (var key in this.formData) {
							if(this.formData[key] && this.formData[key]!=''){
								//添加提交参数
								export_url = export_url + "&";
								export_url = export_url + key +"="+this.formData[key];
							}
						}
					}
					window.open(export_url);
				}
			});
		}
	}
}
</script>